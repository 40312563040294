import { PiTelegramLogoThin } from "react-icons/pi";
export default function Home () {
    return (
        <section className={`grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-5 lg:gap-y-20 mt-10`}>
            <div id="figure-hero" className={`relative`}>
                <div id="bubble-background" className={`absolute w-10 h-10 lg:w-[300px] lg:h-[300px] bg-blue-500 p-20 -z-10 rounded-tl-full top-0 left-0 blur-[1px] bubble-float lg:left-6 lg:top-5`}></div>
                <div id="name-card-container" className={`w-full absolute p-2 flex justify-center -bottom-5 namecard-float z-10`}>
                    <div id="name-card" className={`bg-white p-2 border-2 z-10 w-[250px] text-sm bottom-0 left-15`}>
                        <div id="name" className={`flex justify-between`}>
                            <p>Name</p>
                            <p className={`underline underline-offset-4`}>Raccoon95</p>
                        </div>
                        <div id="age" className={`flex justify-between`}>
                            <p>Age</p>
                            <p className={`underline underline-offset-4`}>21</p>
                        </div>
                        <div id="occupation" className={`flex justify-between`}>
                            <p>Occupation</p>
                            <p className={`underline underline-off-set-4`}>
                                CEO of Raccoon95
                            </p>
                        </div>
                    </div>
                </div>
                <img src="/figures/ceo-1.png" alt="ceo-raccoon" className={`rounded-b-full mx-auto drop-shadow-lg`} />
            </div>
            <div id="project-desc" className={`lg:flex lg:flex-col lg:justify-center`}>
                <h1 className={`text-5xl lg:text-6xl font-bold`}>Raccoon<span className={`bg-clip-text bg-gradient-to-b from-blue-500 to-red-500 text-transparent`}>95</span></h1>
                <p className={`text-2xl lg:text-3xl font-extralight`}>A groundreaking meme office that onboard people to work inside the company of Raccoon95. Work as a 9 to 5 now.</p>
                <div className={`pt-4`}>
                    <a href="/" className={`bg-blue-500 py-2 px-10 text-white text-lg rounded-lg uppercase`}>
                        Explore Job
                    </a>
                </div>
            </div>
            <div id="hiring-post" className={`flex lg:flex-row flex-col lg:col-span-2 lg:mx-[10vw]`}>
                <div id="the-job" className={`bg-blue-500 lg:rounded-tl-xl lg:rounded-bl-xl rounded-tl-xl rounded-tr-xl lg:rounded-tr-none p-5 text-white w-full text-center lg:flex lg:flex-col lg:justify-center`}>
                    <h1 className={`lg:text-3xl text-2xl uppercase`}>Opportunity</h1>
                </div>
                <div id="direct-to-miniapp" className={`p-5 bg-white w-full lg:rounded-tr-xl lg:rounded-br-xl rounded-bl-xl rounded-br-xl lg:rounded-bl-none space-y-2`}>
                    <h1 className={`lg:text-xl text-xl font-bold`}>Apply as a <span className={`bg-gradient-to-b from-blue-500 to-red-500 bg-clip-text text-transparent`}>Raccoon95</span> employee</h1>
                    <p>Talk to <span className={`underline underline-offset-4`}>Human Resource Department</span> to apply as an employee of <span className={`font-bold tracking-wide`}>Raccoon95</span>.</p>
                    <div className={`lg:pt-5 text-white`} id="link-btn">
                        <a href="/" className={`bg-blue-500 lg:py-2 lg:px-10 py-1 px-4 lg:rounded-xl rounded-lg flex space-x-2 max-w-fit`}>
                            <PiTelegramLogoThin size={20} className={`my-auto`} />
                            <p className={`my-auto`}>Telegram MiniApp</p>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}