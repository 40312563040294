export default function Header () {
    return (
        <section className={`flex space-x-2 justify-between`}>
            <div className={`my-auto`}>
                <a href="/">
                    <h1 className={`font-bold text-2xl`}>Raccoon<span className={`bg-clip-text bg-gradient-to-b from-blue-500 to-red-500 text-transparent`}>95</span></h1>
                </a>
            </div>
            <div className={`my-auto`}>
                <a href="/" className={`bg-blue-500 text-white py-2 px-5 rounded-lg uppercase font-bold tracking-widest`}>
                    Apply Job
                </a>
            </div>
        </section>
    )
}